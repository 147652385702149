import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CircularProgress, Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'commons/store/hooks';
import Nav from 'commons/wrappers/DashboardLayoutWrapper/components/Nav';
import {
    fetchDependencies,
    fetchEcrf,
    fetchEcrfElements,
    fetchSectionByEcrf,
    fetchVisitsWithCounters,
    getSectionId,
    resetCollection,
    resetCurrentSection,
    resetDependencies,
    resetEcrf,
    resetElementDatas,
    selectCurrentSection,
    selectSectionId,
    selectVisits,
} from 'features/ecrf/redux/ecrfSlice';
import { fetchElements } from 'features/ecrf/redux/sectionSlice';
import { StyledEcrfLayout, StyledEcrfTitle } from './Ecrf.styled';
import { FormContent } from './FormContent/FormContent';
import { LeftPanel } from './Menu/LeftPanel';
import { VisitsPanel } from './VisitsList/VisitsPanel';
import { useSnackbar } from 'notistack';
import { ROUTES_ECRF } from 'commons/constants/paths';
import { useTranslation } from "react-i18next";

export const EcrfForm: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { id, section } = useParams<{ id: string, section: string }>();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    /**
     * State is holding props from routing redirect
     * @example: from dashboard, ae or query
     */
    const redirectSection = useAppSelector(selectSectionId);

    /** Current selected section data */
    const currentSection = useAppSelector(selectCurrentSection);

    /** Visits */
    const visits = useAppSelector(selectVisits);

    /** State is holding actual selected visit */
    const [selectedVisit, setSelectedVisit] = useState<any>({});

    /** State is holding actual selected section */
    const [selectedSection, setSelectedSection] = useState<any>({});

    /** Initial loading state status */
    const [initialLoading, setInitialLoading] = useState(true);

    /** Response pending status - if is true you can't change section */
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [dataLoading, setDataLoading] = useState(t('loading-data') + '...')

    /**
     * Initial config - load first section of first visit
     */
    const initialConfig = async (visits: any) => {
        setIsDataLoading(true);

        if (!visits) return;
        setSelectedVisit(visits[0]);

        if (visits.length === 0 || visits?.[0]?.sections?.length === 0) {
          setIsDataLoading(false);
          return;
        }

        if (visits[0].sections[0].id) {
            setSelectedSection(visits[0].sections[0]?.id);

            (id && !section) && navigate(ROUTES_ECRF.DETAILS_EXTENDED(id, visits[0].sections[0]?.id));

            await loadSection(visits[0].sections[0].id, visits);
            await loadSectionElements(visits[0].sections[0].id);
        }

        setIsDataLoading(false);
    };

    /**
     * loadSectionElements()
     */
    const loadSectionElements = async (sectionId: any) => {
        await dispatch(fetchElements(sectionId));
        setDataLoading(t('loading-field-dependencies') + '...');
        await dispatch(fetchDependencies(sectionId));
        setDataLoading(t('loading-field-data') + '...');
        await dispatch(fetchEcrfElements({ id, sectionId }));
    }

    /**
     * loadSection()
     * @todo one fn reload current section (elements, data, etc)
     */
    const loadSection = async (sectionId: any, visits: any = []) => {
        setSelectedSection(sectionId);
        setDataLoading(t('loading-section-data') + '...');

        // const section: any = await dispatch(fetchSection(sectionId)).unwrap();
        const _section: any = await dispatch(fetchSectionByEcrf({ id: sectionId, ecrfId: id })).unwrap();

        if (id && sectionId) {
            !section && navigate(ROUTES_ECRF.DETAILS_EXTENDED(id, sectionId), { replace: true })
            setSelectedSection(_section?.id);
        }

        console.log('loadSection')
        console.log(sectionId)
        console.log(_section)
        console.log(visits)
        if ((visits || visits?.length > 0) && _section?.visit?.id) {
            const visit = visits?.find((visit: any) => visit?.id === _section?.visit?.id);
            if (visit) setSelectedVisit(visit);
        }
    };

    /**
     * loadSectionData()
     */
    const loadSectionData = async (sectionId: any, visits: any = []) => {
      if (isDataLoading) return;
        setIsDataLoading(true);

        try {
            await loadSection(sectionId, visits);
            await loadSectionElements(sectionId);
        } catch (error: any) {
            enqueueSnackbar(error?.detail || error?.message, { variant: 'error' });
        }

        /** clear redirect-data after redirect */
        dispatch(getSectionId({}));

        setIsDataLoading(false);
    };

    /** Config for redirect option */
    const redirectConfig = async (visits?: any) => {
        setIsDataLoading(true);

        if (redirectSection?.section) {
            await loadSection(redirectSection.section, visits);
            await loadSectionElements(redirectSection.section);
        }

        if (redirectSection?.element) {
            setIsOpenQueryPanel(redirectSection.element);
        }

        /** clear redirect-data after redirect */
        dispatch(getSectionId({}));

        setIsDataLoading(false);
    };

    /** Config for section from route params */
    const routeSectionConfig = async (sectionId: string, visits?: any) => {
        setIsDataLoading(true);

        try {
            await loadSection(sectionId, visits);
            await loadSectionElements(sectionId);
        } catch (e: any) {
            await initialConfig(visits);
        }

        setIsDataLoading(false);
    };

    /** Status of Queries Panel */
    const [isOpenQueryPanel, setIsOpenQueryPanel] = useState<null | string>(null);

    /**
     * Used to fetch patient data including ECRF data and visits
     */
    const fetchEcrfData = async () => {
        // init data ecrf
        setInitialLoading(true);

        setDataLoading(t('loading-visits') + '...')
        const visits = await dispatch(fetchVisitsWithCounters(id)).unwrap();
        setDataLoading(t('loading-ecrf-data') + '...');
        const ecrf = id && await dispatch(fetchEcrf(id))?.unwrap();

        if (!ecrf) {
            return;
        }

        // init data section
        // incoming redirect data (AE, Query)
        if (redirectSection?.section !== undefined) {
            await redirectConfig(visits);
        } else if (section) {
            await routeSectionConfig(section, visits);
        // if exists current `section` in ecrf data
        } else if (ecrf?.currentSection?.id) {
            // await fetchSectionData(section || ecrf?.currentSection?.id, visits);
            await loadSectionData(ecrf?.currentSection?.id, visits);
        } else {
            await initialConfig(visits);
        }

        setInitialLoading(false);
    }

    useEffect(() => {
      // console.log('useEffect 1')
        fetchEcrfData().then();

        /** clear state after unmount */
        return () => {
            dispatch(resetEcrf());
            dispatch(resetCurrentSection());
            dispatch(resetElementDatas());
            dispatch(resetCollection());
            dispatch(resetDependencies());
        };
    }, [id]);

    useEffect(() => {
        if ((section && currentSection?.id) && section !== currentSection?.id) {
            loadSectionData(section, visits);
        }
    }, [section])

    return (
        <>
            <Nav />
            {!initialLoading && (
                <StyledEcrfLayout>
                    <LeftPanel
                        selectedVisit={selectedVisit}
                        selectedSection={selectedSection}
                        setSelectedSection={setSelectedSection}
                        isDataLoading={isDataLoading}
                        setIsDataLoading={setIsDataLoading}
                    />
                    <VisitsPanel
                        selectedVisit={selectedVisit}
                        setSelectedVisit={setSelectedVisit}
                        isDataLoading={isDataLoading}
                    />
                    <FormContent
                        isDataLoading={isDataLoading}
                        setSelectedVisit={setSelectedVisit}
                        setIsDataLoading={setIsDataLoading}
                        isOpenQueryPanel={isOpenQueryPanel}
                        setIsOpenQueryPanel={setIsOpenQueryPanel}
                        updateSection={loadSectionData.bind(this)}
                    />
                </StyledEcrfLayout>
            )}

            {/** Initial view with loading animation */}
            {initialLoading && (
                <Box display="grid" style={{placeItems: 'center', height: '100vh'}}>
                    <Box display="grid" style={{placeItems: 'center', gap: '15px'}}>
                        <CircularProgress />
                        <StyledEcrfTitle>{dataLoading}</StyledEcrfTitle>
                    </Box>
                </Box>
            )}
        </>
    );
};

export default EcrfForm;
